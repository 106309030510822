import * as storage from "../common/storage"
import { DeviceUUID } from "device-uuid";

interface State {
  uuid: string;
  language: string;
  platform: string;
  os: string;
  cpuCores: number;
  colorDepth: number;
  isMobile: boolean;
  isDesktop: boolean;
}

export const state = (): State => ({
  uuid: '',
  language: '',
  platform: '',
  os: '',
  cpuCores: 0,
  colorDepth: 0,
  isDesktop: false,
  isMobile: false,
})

export const mutations = {
  set_device(state: any, value: string) {
    console.log("🛒 store/device::mutations:set_device", value);
    state.device = value;
    storage.set(storage.POMELO_DEVICE, state);
  },
  set_uuid(state: any) {
    const device = new DeviceUUID().parse();
    console.log("🛒 store/device::mutations:set_uuid", device);
    state.uuid = new DeviceUUID().get();
    state.language = device.language;
    state.platform = device.platform;
    state.os = device.os;
    state.cpuCores = device.cpuCores;
    state.colorDepth = device.colorDepth;
    state.isMobile = device.isMobile;
    state.isDesktop = device.isDesktop;
    storage.set(storage.POMELO_DEVICE, state);
  },
}

export const actions = {
  // load from LocalStorage
  async init(context: any) {
    console.log('🛒 store/device::action:init');
    if ( typeof navigator === 'undefined') context.commit("set_uuid", "nodejs_env");
    else context.commit("set_uuid");
  },
}


export const getters = {
  async isDesktop(state: any) {
    const device = new DeviceUUID().parse();
    return device.isDesktop;
  },
}
