import * as storage from "../common/storage"

interface State {
  locale: string;
  accepted: Set<string>;
}

export const state = (): State => ({
  locale: 'en',
  accepted: new Set(['en', 'zh', 'ko']),
})

export const getters = {}

export const mutations = {
  set_locale(state: State, locale: string) {
    if (!state.accepted.has(locale)) locale = 'en';
    state.locale = locale;
    storage.set(storage.POMELO_LANGUAGE, locale);
  },
}

export const actions = {
  init(context: any) {
    let locale = storage.get( storage.POMELO_LANGUAGE )
    console.log('store/languageStore::init', { locale });
    context.commit("set_locale", locale);
  },
}

