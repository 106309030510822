import dayjs from "dayjs";

export function is_valid_eosio_account( account: string ) {
  console.log({function: "is_valid_eosio_account", account })
  return /^[a-z1-5\.]{1,12}$/.test(account);
}

export function is_valid_eosn_account( account: string ) {
  console.log({function: "is_valid_eosn_account", account })
  return /^[a-z\d]([a-z\d]|\.([a-z\d])){2,38}$/i.test(account);
}

export function is_valid_eosn_characters( account: string ) {
  console.log({function: "is_valid_eosn_characters", account })
  return /^[a-z\d\.]{1,38}$/i.test(account);
}

export async function timeout( ms: number ): Promise<void> {
  return new Promise(resolve => setTimeout(() => resolve(), ms));
}

export function getQueryString( name: string ) {
  console.log("☎️ common/helpers:getQueryString", { name });
  return new URLSearchParams(window.location.search).get(name);
  // const reg = `(^|&)${name}=([^&]*)(&|$)`
  // const r = window.location.search.substr(1).match(reg);
  // if (r != null) return unescape(r[2]); return null;
}
export function getQueryHash() {
  const hash = window.location.href.split('#')[1];
  return hash ? '#' + hash : '';
}

export function dateFormat( date: string, template: string, utc = false ) {
  if (utc) return dayjs(date).add(-dayjs().utcOffset(), 'm').format(template);
  return dayjs(date).format(template)
}

export function emailIsValid( email: string ) {
  return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/.test( email );
}

export function resized_image_url( url: string, size: number = 1500, animated: boolean = true ) {
  if (!url) return '';
  if (url.includes('cdn.eosnation.io')){
    let resize = size;
    if(size < 300) resize = 200;  //only 200/500/1500
    else if(size < 700) resize = 500;
    return `${url}?webp=true&resize=${resize}${animated ? '&animated=true' : ''}`
  }
  // const parts = url.split("/");
  // const hash = parts[parts.length - 1].split(".")[0]
  if ( url.includes(".svg") ) return url;
  throw Error(`invalid resized image url=${url}`);
  // return `https://functions.eosn.io/v2/ipfs/${hash}?size=500&url=${url}`
}

export function getTwitterUrl( twitter_handle: string ) {
  if(twitter_handle.startsWith('http:') || twitter_handle.startsWith('https:')) return twitter_handle;
  return "https://twitter.com/" + twitter_handle;
}

export function getUrl( website: string ) {
  if(website.startsWith('http:') || website.startsWith('https:')) return website;
  return "https://" + website;
}


export function normalizeUrl(url: string) {
  if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url;
  }
  url = url.replace(/[^a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]/g, '');

  return url;
}

export function normalizeTwitter(twitter: string) {
  twitter = twitter.replaceAll('https://twitter.com/', '');
  twitter = twitter.replaceAll('twitter.com/', '');
  twitter = twitter.replace(/[^a-zA-Z0-9_]/g, '');
  twitter = twitter.slice(0,15);
  return twitter;
}

export function formatCurrency(amount: number, currency: string = "USD", fractionDigits: number = 2) {
  const amountStr = amount.toLocaleString('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  });
  return `${amountStr} ${currency}`
}

export function parseNumber(numstr) {
  if(!numstr) return 0;
  return +numstr.replace(/,/g, '');
}


// export function formatedUserWebsite() {
//   let hasHttpOrHttps = false;
//   if (this.$store.state.userStore!=undefined) {
//       hasHttpOrHttps = this.$store.state.userStore.userProfile.website.indexOf('http://')!=-1 || this.$store.state.userStore.userProfile.website.indexOf('https://')!=-1
//       if (hasHttpOrHttps) {
//           return this.$store.state.userStore.userProfile.website;
//       }
//       else {
//           return 'https://'+this.$store.state.userStore.userProfile.website;
//       }
//   }
//   else{
//       return '';
//   }
// }

export function slice_text( url: string, length = 30 ) {
  if (!url) return '';
  if (url.length < length) return url;
  return url.slice(0, length).trim() + "..."
}

export function toTitleCase( str: string ) {
  if ( !str ) return '';
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function strip_tags( html: string ) {
  return html
    .replaceAll(/<p.*?>(.*?)<\/p>/ig,'$1')
    .replaceAll(/<a.*?>(.*?)<\/a>/ig,'$1')
    .replaceAll(/<li.*?>(.*?)<\/li>/ig,'$1')
    .replaceAll(/<strong.*?>(.*?)<\/strong>/ig,'$1')
    .replaceAll(/<h.*?>(.*?)<\/h.*?>/ig,'$1')
    .replaceAll(/<em.*?>(.*?)<\/em.*?>/ig,'$1')
    .replaceAll(/<u.*?>(.*?)<\/u.*?>/ig,'$1')
    .replaceAll(/<img.*?>/ig,'')
    .replaceAll(/<pre>/ig,'')
    .replaceAll(/<u>/ig,'')
}
