import * as storage from "@/common/storage"

interface State {
    seenGrants: string[];
}

export const state = () => ({
    seenGrants: [],
})

export const mutations = {
    setSeen(state: State, grantNames: string[]) {
        state.seenGrants = Array.from(new Set([...state.seenGrants, ...grantNames]));
        storage.set(storage.POMELO_GRANTS, state);
    },
    setUnseen(state: State, grantName: string) {
        state.seenGrants = state.seenGrants.filter((grant: string) => grant !== grantName);
        storage.set(storage.POMELO_GRANTS, state);
    },
}

export const actions = {
    // init from localstorage
    init(context: any) {
        const grants = storage.get( storage.POMELO_GRANTS )
        console.log('store/grantsStore::init', { grants });
        context.commit("setSeen", Array.from(new Set(grants?.seenGrants ?? [])));
    },
}