import { Profile, get_pfp_profiles } from "pomelo-sdk";
import * as storage from "../common/storage"
import { IS_DEV } from "@/common/constants"

const CACHE_MINUTES = 5
type Profiles = { [key: string]: Profile };

interface State {
  profiles: Profiles;
  updated: Date;
}

export const state = (): State => ({
  profiles: {},
  updated: new Date(0),
})

export const getters = {
  profile: (state: State) => (eosn_id: string) => {
    return state.profiles[eosn_id] ?? {};
  },
}

export const mutations = {
  set_profiles(state: State, payload: Profile[]) {
    if (!payload.length) return; // skip if empty
    const profiles = payload.reduce((acc: {[key: string]: Profile}, cur: Profile) => {
      if (cur.eosn_id) acc[cur.eosn_id] = cur;
      return acc;
    }, {});
    state.profiles = profiles;
    state.updated = new Date();
    console.log('store/pfp::set_profiles', { profiles });
    storage.set(storage.POMELO_PFP, state);
  },
  // update state.profiles without changing updated
  set_state(state: State, loadedState: State) {
    console.log('store/pfp::set_state', { loadedState });
    if (Object.keys(loadedState.profiles ?? {}).length == 0) return; // skip if empty
    state.profiles = loadedState.profiles;
    state.updated = loadedState.updated;
  },
  set_profile(state: State, profile: Profile) {
    if (!profile?.eosn_id) return; // skip if empty

    // normalize data & add defaults
    state.profiles[profile.eosn_id] = profile;
    console.log('store/pfp::set_profile', { profile });
    storage.set(storage.POMELO_PFP, state);
  },
}

export const actions = {
  async init(context: any) {
    console.log('store/pfp::init', { context, dev: IS_DEV });
    // load from storage (for opened in new page)
    if (Object.keys(context.state.profiles).length == 0) {
      const stored = storage.get(storage.POMELO_PFP);
      if (stored) context.commit("set_state", stored);
    }
    // refresh no often than CACHE_MINUTES minutes
    if (Object.keys(context.state.profiles).length && new Date().getTime() - new Date(context.state.updated).getTime() < CACHE_MINUTES*60*1000) return;
    const profiles = await get_pfp_profiles(IS_DEV)
    context.commit("set_profiles", profiles);
  },
  async init_profile(context: any, account: string) {
    console.log('store/pfp::init_profile', { account });
    if (!context.state.profiles || !account) return;
    const profiles = await get_pfp_profiles(IS_DEV, account)
    context.commit("set_profile", profiles[0]);
  },
}
