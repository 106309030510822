

export default {
  components: {
  },
  props: {
  },
  computed: {
    grantToAdd() {
      return this.$store.state.collections.grantToAdd;
    },
    visible: {
      get() { return this.grantToAdd != ''; },
      set(newValue) { this.$store.commit('collections/set_grant_to_add', newValue ? 'dummy': ''); }
    },
    username() {
      return this.$store.state.userStore?.userProfile?.username ?? ''
    }
  },
  data() {
    return {
      collectionName: '',
      collections: [],
      submitted: false,
    };
  },
  watch: {
    "grantToAdd": {
      handler:function(newVal,oldVal){
        if(newVal != '') this.loadMyCollections();
      }
    }
  },
  mounted() {
  },
  methods: {
    onAddGrantClick() {
      if(!this.collectionName || !this.grantToAdd) return;
      this.submitted = true;
      this.$api.addGrantToCollection({},{}, this.collectionName, this.grantToAdd)
      .then(res => {
        this.$message.success(this.$t('collection.flash_msg_add_grant_success'));
        this.$store.commit('collections/set_grant_to_add','');
        this.collections.find(col => col.name === this.collectionName)?.logos?.push('');  // until refresh
      })
      .catch(err => {
        this.$message.error(this.$t('collection.flash_msg_add_grant_failed') + ": " + this.$t(`rest_api.${err.message}`, {param: err.detail}));
      })
      .finally(() => {
        this.submitted = false;
      })
    },
    onCancel(e) {
      this.$store.commit('collections/set_grant_to_add','');
    },
    loadMyCollections() {
      if(!this.username) return;
      this.$api.userCollections({},{'sort[]': 'updated_at:desc'})
      .then(res => {
        this.collections = res.data;
        this.collectionName = this.collections?.[0]?.name ?? ''
      })
      .catch(err => {
        console.log('Failed to load collections for user:', this.username, err)
      })
    },
  }
};
