import { API } from "@/common/API"

interface RoundsInfo {
    current: RoundOverview[];
    previous: RoundOverview[];
    next: RoundOverview[];
}

interface RoundOverview {
    matching_round_id: number;
    title: string;
    description: string;
    rules: string;
    amount_usd: string;
    start_at: string;
    end_at: string;
    finalized_at: string;
    season_id: number;
    submissions_start_at: string;
    submissions_end_at: string;
    matching_partners: { name: string }[];
    approved_tokens: {id: number, precision: number, symbol: string}[];

}

interface SeasonInfo {
    season_id: number;
    description: string;
    title: string;
    match_value: string;
    start_at: string;
    end_at: string;
    submissions_start_at: string;
    submissions_end_at: string;
    finalized_at: string;
    matching_rounds: any[];
}

interface State {
    roundInfo: RoundsInfo;
    seasons: SeasonInfo[]
    updated: Date;
}

export const state = (): State => ({
    roundInfo: null,
    seasons: null,
    updated: new Date(0),
})


export const getters = {
    getActiveRounds: (state: State) => () => {
        if(state.roundInfo?.current?.length)
            return state.roundInfo.current;

        if(state.roundInfo?.next?.find(rnd => new Date() > new Date(rnd.submissions_start_at)))
            return state.roundInfo.next;

        // just ended and not finalized - still assume active
        if(state.roundInfo?.previous?.length)
            return state.roundInfo.previous.filter(rnd => !rnd.finalized_at);

        return null;
    },
    getActiveSeasonId: (state: State) => () => {
        if(state.roundInfo?.current?.length)
            return state.roundInfo.current[state.roundInfo.current.length-1]?.season_id;

        // submission started but donations not yet
        if(state.roundInfo?.next?.find(rnd => new Date() > new Date(rnd.submissions_start_at)))
            return state.roundInfo.next[0]?.season_id;

        // just ended and not finalized - still assume active
        if(state.roundInfo?.previous?.length)
            if(!state.roundInfo.previous[state.roundInfo.previous.length-1].finalized_at)
            return state.roundInfo.previous[state.roundInfo.previous.length-1]?.season_id;

        return null;
    },
    getActiveSeason: (state: State) => () => {
        //take the first non-finalized season
        return state.seasons?.find((season: SeasonInfo) => !season.finalized_at/* new Date(season.end_at) > new Date()*/);
    },
    isSubmissionOnlyPeriod: (state: State) => () => {
        return !!state.seasons?.find((season: SeasonInfo) => new Date() > new Date(season.submissions_start_at) && new Date(season.start_at) > new Date());
    },
}

export const mutations = {
    setRoundInfo(state: any, val: any) {
        console.log("🛒 store/roundStore::mutations:setRoundInfo", val);
        state.roundInfo = val;
        state.updated = new Date();
    },
    setSeasonInfo(state: any, val: any) {
        console.log("🛒 store/roundStore::mutations:setSeasonInfo", val);
        state.seasons = val;
        state.updated = new Date();
    },
}

export const actions = {
    async queryRounds(context: any, force: boolean = false) {
        // 60 minutes cache
        if (!force && context.state.roundInfo && context.state.seasons && new Date().getTime() - context.state.updated.getTime() < 60*60*1000) return;
        const [rounds, seasons] = await Promise.all([API.listActiveRounds({}, {}), API.listSeasons({}, {})]);
        context.commit("setRoundInfo", rounds?.data);
        context.commit("setSeasonInfo", seasons?.data);
    },
}