
interface Translation {
    title: string;
    language_id: number;
    summary: string;
    description: string;
    last_season_update: string;
}

export function sanitizeGrant(grant: any) {
    grant?.grant_translations?.forEach((translation: Translation) => {
        translation.description = sanitize(translation.description);
        translation.last_season_update = sanitize(translation.last_season_update);
    })
    return grant;
}


export function deSanitizeGrant(grant: any) {
    grant.grant_translations?.forEach((translation: Translation) => {
        translation.description = deSanitize(translation.description);
        translation.last_season_update = deSanitize(translation.last_season_update);
    })
    if(grant.description) grant.description = deSanitize(grant.description);
    if(grant.last_season_update) grant.last_season_update = deSanitize(grant.last_season_update);
    return grant;
}


function getYoutubeSlug(url) {
    const match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
        url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
        url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
    return match?.[2];
}

export function getEmbedVideoUrl(url) {
    const slug = getYoutubeSlug(url);
    return slug ? `https://www.youtube.com/embed/${slug}` : '';

    // if (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/)) { // eslint-disable-line no-cond-assign
    //     return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
    // }
  }

function sanitize(content: string): string {
    let res = content?.replace(/<iframe.+?embed\/(.+?)\".+?iframe>/g, '[youtube]$1[/youtube]');

    return res;
}

function deSanitize(content: string): string {
    let res = content?.replace(/\[youtube\](.+?)\[\/youtube\]/g,'<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="https://www.youtube.com/embed/$1"> </iframe>');
    return res;
}