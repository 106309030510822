// import { POMELO_TOKEN_SYMCODE } from "@/common/constants"
import { loginLink, loginUnlink } from "../common/actions"
import { get_currency_balance, get_eosn_login_acount } from "../common/getters"
import { timeout } from "../common/helpers"
import WalletJS from "eosio-wallets"
import { Wallet } from "eosio-wallets/dist/wallet"
import * as storage from '../common/storage'
import { IDENTIFIER, EOSIO_CHAIN_ID, EOSIO_RPC, COSIGN_ENDPOINT, COSIGN_REFERRER, TOKEN_CONTRACTS } from '../common/constants'

WalletJS.init({
  rpcEndpoint: EOSIO_RPC,
  chainId: EOSIO_CHAIN_ID,
  appId: IDENTIFIER,
  cosignEndpoint: COSIGN_ENDPOINT,
  cosignReferrer: COSIGN_REFERRER
})

interface State {
  wallet: Wallet;
  eosn_id: string;
  linkedAccount: string;
  visible: boolean;
  status: string;
  balance: string;
  balances: string[];
}

export const DEFAULT_WALLET: Wallet = {
  actor: "",
  permission: "",
  publicKey: "",
  wallet: "",
  protocol: "",
  chain: "eos",
}
export const DEFAULT_BALANCE = `0.0000`;

export const state = (): State => ({
  wallet: DEFAULT_WALLET,
  eosn_id: '',
  linkedAccount: '',
  balance: DEFAULT_BALANCE,
  balances: [],
  visible: false,
  status: '',
})

function saveWallet( payload: Wallet) {
  storage.add("user-v.0.0.1", payload);
}

function getWallet(): Wallet {
  return storage.get("user-v.0.0.1");
}

function removeWallet() {
  return storage.remove("user-v.0.0.1");
}

function existsWallet() {
  return Object.keys(getWallet() || {}).length > 0;
}


export const mutations = {
  set_wallet(state: any, payload: Wallet) {
    console.log("🛒 store/wallet::mutations:set_wallet", payload);
    state.wallet = payload || DEFAULT_WALLET;
    saveWallet(payload);
  },
  set_eosn_id(state: any, value: string) {
    console.log("🛒 store/wallet::mutations:set_eosn_id", value);
    state.eosn_id = value;
    if ( !state.eosn_id ) {
      state.linkedAccount = "";
      state.balance = DEFAULT_BALANCE;
    }
  },
  set_linkedAccount(state: any, value: string) {
    console.log("🛒 store/wallet::mutations:set_linkedAccount", value);
    state.linkedAccount = value;
  },
  set_balance(state: any, value: string) {
    console.log("🛒 store/wallet::mutations:set_balance", value);
    state.balance = value;
  },
  set_balances(state: any, value: string[]) {
    console.log("🛒 store/wallet::mutations:set_balances", value);
    state.balances = value;
  },
  set_visible(state: any, value: boolean) {
    console.log("🛒 store/wallet::mutations:set_visible", value);
    state.visible = value;
  },
  set_status(state: any, status: string) {
    console.log("🛒 store/wallet::mutations:set_status", status);
    state.status = status;
  },
}

export const actions = {
  // load from LocalStorage
  async init(context: any) {
    const payload = getWallet();
    console.log('🛒 store/wallet::action:init', payload );
    context.commit("set_wallet", payload);
    // const isDesktop = await context.rootGetters["device/isDesktop"];
    if (!payload.actor) context.dispatch("login_scatter");
  },

  // pull new data from on-chain
  async refresh(context: any ) {
    const { eosn_id } = context.state;
    console.log('🛒 store/wallet::action:refresh', { eosn_id } );
    const linkedAccount = await get_eosn_login_acount( eosn_id );
    context.commit("set_linkedAccount", linkedAccount);
    context.dispatch("refresh_balance");
  },
  // pull new data from on-chain
  async refresh_balance(context: any ) {
    const { linkedAccount } = context.state;
    console.log('🛒 store/wallet::action:refresh_balance', { linkedAccount } );
    if ( !linkedAccount ) {
      context.commit("set_balance", DEFAULT_BALANCE);
      return;
    }
    // const balance = await get_currency_balance( linkedAccount, "eosio.token", "EOS" );
    // context.commit("set_balance", balance);

    const balances = [];
    for ( const [symcode, contract] of Object.entries(TOKEN_CONTRACTS)) {
      try {
        const balance = await get_currency_balance( linkedAccount, contract, symcode );
        const amount = Number(balance.split(' ')[0]);
        if ( amount === 0) continue;
        balances.push(balance);
      } catch (e) {
        console.error('🛒 store/wallet::action:refresh_balance', { symcode, contract });
      }
    }
    const balance = balances[0];
    context.commit("set_balance", balance);
    context.commit("set_balances", balances);
  },
  // set EOSN Account & trigger refresh
  async set_eosn_id(context: any, eosn_id: string) {
    console.log('🛒 store/wallet::action:set_eosn_id', { eosn_id } );
    context.commit("set_eosn_id", eosn_id);
    if ( !eosn_id ) return;
    context.dispatch("refresh");
  },

  async set_visible(context: any, value: boolean) {
    console.log('🛒 store/wallet::action:set_visible', { value } );
    context.commit("set_visible", value);
    if ( value ) context.dispatch("refresh"); // only refresh on visible
    // attempt auto-login Anchor if no actor found
    // if (value && !context.state.wallet.actor) context.dispatch("login_anchor");
  },

  async disconnect( context: any ) {
    console.log('🛒 store/wallet::action:disconnect');
    removeWallet();
    context.commit("set_status", 'Disconnected');
    await WalletJS.logout();
    context.commit("set_wallet", DEFAULT_WALLET);
  },

  async auto_refresh(context: any ) {
    context.dispatch("refresh");
    await timeout(1000);
    context.dispatch("refresh");
    await timeout(2000);
    context.dispatch("refresh");
  },

  async login_anchor(context: any ) {
    console.log('🛒 store/wallet::action:login_anchor');
    context.commit("set_status", 'Connecting');
    try {
      // Perform the login or restores session, which returns the users identity
      const { actor, permission } = await WalletJS.login("anchor");

      // default wallet values
      const wallet = "anchor";
      const protocol = "anchor";
      const chain = 'eos';
      context.commit("set_status", '');

      // save
      context.commit("set_wallet", {actor, permission, publicKey: '', wallet, protocol, chain });
      context.dispatch("refresh");
    } catch (err) {
      context.commit("set_status", 'Not connected');
      console.error('🛒 store/wallet::action:login_anchor', {err});
    }
  },
  async login_scatter(context: any ) {
    console.log('🛒 store/wallet::action:login_scatter');
    context.commit("set_status", 'Connecting');
    try {
      const { actor, permission } = await WalletJS.login('scatter');

      // default wallet values
      const active_wallet = WalletJS.getWallet() || "scatter";
      const protocol = 'scatter';
      const chain = 'eos';
      context.commit("set_status", '');

      // save
      context.commit("set_wallet", {actor, permission, publicKey: '', wallet: active_wallet, protocol, chain });
      context.dispatch("refresh");
    } catch (err) {
      context.commit("set_status", 'Not connected');
      console.error('🛒 store/wallet::action:login_scatter', {err});
    }
  },

  async unlink(context: any ) {
    const { linkedAccount, eosn_id } = context.state;
    const { protocol } = context.state.wallet;
    console.log('🛒 store/wallet::action:unlink', { eosn_id, linkedAccount, protocol});
    if ( !linkedAccount ) throw new Error("[linkedAccount] is required")
    const action = loginUnlink( eosn_id, linkedAccount );

    // push transaction
    try {
      const txid = await WalletJS.pushTransaction( [ action ], protocol || "anchor", true );
      console.log('🛒 store/wallet::action:unlink', { txid });
      context.dispatch("disconnect");
      context.commit("set_linkedAccount", "");
    } catch (err) {
      console.log('🛒 store/wallet::action:unlink', { err });
      this.$message.error(err.message || err, 8);
      throw new Error(err);
    }
  },

  async link(context: any, sig: string ) {
    const { eosn_id } = context.state;
    const { protocol, actor } = context.state.wallet;

    if ( !eosn_id ) {
      this.$message.error("[eosn_id] is required");
      throw new Error("[eosn_id] is required");
    }
    if ( !sig ) {
      this.$message.error("[sig] is required");
      throw new Error("[sig] is required");
    }
    console.log('🛒 store/wallet::action:link', { eosn_id, actor, protocol, sig });
    const action = loginLink( eosn_id, actor, sig );

    // push transaction
    try {
      const txid = await WalletJS.pushTransaction([action], protocol || "anchor", true );
      console.log('🛒 store/wallet::action:link', { txid });
      context.commit("set_linkedAccount", actor);
      context.dispatch("refresh_balance");
    } catch (err) {
      console.log('🛒 store/wallet::action:link', { err });
      this.$message.error(err.message || err, 8);
      throw new Error(err);
    }
  },
}
