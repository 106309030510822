import Vue from 'vue'
// import Antd from 'ant-design-vue/lib'
// Vue.use(Antd)

import {
    Layout,
    Row,
    Col,
    Menu,
    Icon,
    Button,
    Form,
    Select,
    Input,
    InputNumber,
    Checkbox,
    Popover,
    Tooltip,
    Rate,
    Spin,
    Modal,
    Tabs,
    List,
    Dropdown,
    Badge,
    Pagination,
    Collapse,
    Drawer,
    Upload,
    ConfigProvider,
    Empty,
    message } from 'ant-design-vue'


Vue.use(Layout)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Menu)
Vue.use(Icon)
Vue.use(Form)
Vue.use(Select)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Rate)
Vue.use(Spin)
Vue.use(Modal)
Vue.use(Tabs)
Vue.use(List)
Vue.use(Dropdown)
Vue.use(Badge)
Vue.use(Pagination)
Vue.use(Collapse)
Vue.use(Drawer)
Vue.use(message)
Vue.use(ConfigProvider)
Vue.use(Empty)


export default ({ app }, inject) => {
    inject( "message", message );
    inject( "popup", Modal );
}


