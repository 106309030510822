
export default {
  data() {
    return { };
  },
  props: {
    eosn_id: String,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
  },
  components: {},
  computed: {
    border() {
      const { border } = this.profile;
      switch ( border?.template_id ) {
          case 1797: // Golden Pomelo
          case 2657: // S1 Badge
            return "golden-border";
          case 4741: // Diamond Pomelo
          case 5035: // S2 Badge
            return "diamond-border";
          case 6844: // Pomelo Moon
          case 6845: // Pomelo Token
          case 7022: // Pomelo Astronaut
          // case 5035: // S3 Badge
            return "moon-border"
          default:
            return "no-border";
      }
    },
    size() {
      if ( this.small ) return "small";
      if ( this.medium ) return "medium";
      if ( this.large ) return "large";
      return "small";
    },
    cover() {
      const { avatar } = this.profile;
      return avatar?.collection_name === "genesis.eden" ? "cover" : ''
    },
    profile() {
      if (!this.eosn_id) return {}
      return this.$store.state.pfp.profiles?.[this.eosn_id] ?? {};
    },
    ipfs() {
      const { avatar } = this.profile;
      return avatar?.metadata?.img || "QmSZGEZGdwHbfqi62X25yRoHxyFHCd9HJR9TwG7Tt7BrjG";
    },
  },
  methods: {},
};
