export const authGuard = (to, from, next) => {

    next(vm => {
        console.log('vm', vm);
        console.log('vm.$auth0', vm.$auth0);
        console.log('vm.$auth0.isAuthenticated', vm.$auth0.isAuthenticated);

        const fn = () => {
            if (!vm.$auth0.isAuthenticated) {
                vm.$auth0.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
                return false;
            }
        };

        if (!vm.$auth0.loading) {
            return fn();
        }

        vm.$auth0.$watch("loading", loading => {
            if (loading === false) {
                return fn();
            }
            return false;
        });
    })
};
