interface State {
  search: string;
  sort_by: string;
  seed: string;
  filters: Object;
}

export const state = (): State => ({
  search: "",
  sort_by: "",
  seed: "",
  filters: {}
})

export const mutations = {
  set_search( state: State, value: string ) {
    console.log("🛒 store/search::mutations:set_search", value);
    state.search = value;
  },
  set_sort_by( state: State, value: string ) {
    console.log("🛒 store/search::mutations:set_sort_by", value);
    state.sort_by = value;
  },
  set_seed( state: State, value: string ) {
    console.log("🛒 store/search::mutations:set_seed", value);
    state.seed = value;
  },
  set_filters( state: State, value: Object ) {
    console.log("🛒 store/search::mutations:filters", value);
    state.filters = { ...state.filters, ...value };
  }
}

export const actions = {
  async init(context: any) {
    const search = window.location.search;
    console.log('🛒 store/search::action:init', search );
    context.commit("set_search", search);
  },
}

export const getters = {
  getQueryString: (state) => (name) => {
    return new URLSearchParams(state.search).get(name);
  },
}
