export const state = () => ({
    grantToAdd: "",
    grantToRemove: "",
    showCreatePopup: false,
})

export const mutations = {
    set_grant_to_add(state: any, payload: string) {
        console.log("🛒 store/collections::mutations:set_grant_to_add", payload);
        state.grantToAdd = payload;
    },
    set_grant_to_remove(state: any, payload: string) {
        console.log("🛒 store/collections::mutations:set_grant_to_remove", payload);
        state.grantToRemove = payload;
    },
    set_show_create_popup(state: any, payload: boolean) {
        console.log("🛒 store/collections::mutations:set_show_create_popup", payload);
        state.showCreatePopup = payload;
    },
}

export const actions = {
    async showAddGrant(context: any, grantName: string) {
      console.log('store/collections::showAddGrant', { context });
      context.commit("set_grant_to_add", grantName);
    }
}