
import { GIT_REVISION } from "@/common/constants"
import { dateFormat } from "@/common/helpers"

export default {
  props: {
    stickyBottom: false
  },
  data() {
    return {
      GIT_REVISION,
    }
  },
  async mounted() {},
  methods: {
    dateFormat
  }
};

