import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import * as analytics from '@/common/analytics'
import { getQueryString, getQueryHash } from '@/common/helpers'
// import axios from "axios";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

// let context;
let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}, context) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      };
    },
    methods: {
      log( method, params = {}) {
        console.log(`plugins/auth/authWrapper::${method}`, params);
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
          analytics.login_redirect({user: this.user});
        } catch (e) {
          this.error = e;
          analytics.auth0_error({error: e});
        } finally {
          this.loading = false;
        }
      },
      async setUserLocale(locale) {
        if(!this.isAuthenticated) return;
        try {
          const langs = (await context.$api.listLanguages()).data;
          const lang_id = langs.find(l => l.code == locale)?.id ?? 0;
          await context.$api.modifyUserProfile({ preferred_language_id: lang_id })
        } catch(err) {
          console.error(`Failed to set ${locale} user locale`);
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        this.log("loginWithRedirect")
        analytics.login_redirect({});
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        this.log("getIdTokenClaims")
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        this.log("getTokenSilently")
        analytics.login_silent();
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        this.log("getTokenWithPopup")
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        this.log("logout")
        analytics.logout();
        return this.auth0Client.logout(o);
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    mounted() {
      this.log("mounted")
    },
    async created() {
      this.log("created", {redirectUri, origin: window.location.origin, options, clientId: options.clientId } );

      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri,
        scope: 'openid email profile offline_access',
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          this.error = null;

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        this.loading = false;

        if (this.isAuthenticated) {
          const accessToken = await this.getTokenSilently();
          context.$axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

          try {
            const userProfile = (await context.$api.userProfile()).data;
            context.store.commit("userStore/setUserProfile", userProfile);
            context.store.dispatch("wallet/set_eosn_id", userProfile?.eosn_id);
            if(userProfile.preferred_language) {
              context.store.commit("languageStore/set_locale", userProfile.preferred_language.code);
              context.i18n.locale = userProfile.preferred_language.code
            }
            else {
              this.setUserLocale(context.store.state.languageStore.locale ?? "en");
            }
            const redirectPath = getQueryString("redirectPath");
            console.log("plugins::auth::authWrapper:created", { redirectPath });
            if ( redirectPath ) context.router.push({path: redirectPath + getQueryHash() });
          } catch (err) {
            if(['terms','privacy'].includes(context.router.currentRoute.name)) return;
            if(err.message == 'account_deactivated'){
              context.$message.error( 'Account Deactivated' );  //how to access $t here?
              this.logout({ returnTo: window.location.origin });
              return;
            }
            else if(err.message == 'bad_request_registration_required'){
              context.router.push({path:'/register'});
            }
            else analytics.auth0_error({error: err});
          }
        }
      }
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth0 = useAuth0(options);
  }
};

// export default ({ app }, inject) => {
//   context = app;
// }
