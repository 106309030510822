import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as storage from "@/common/storage";

Vue.use(VueI18n)

const en = require('@/common/locales/en.json');
const zh = require('@/common/locales/zh.json');
const ko = require('@/common/locales/ko.json');


function detectBrowserLanguage() {
  console.log("detectBrowserLanguage")
  return navigator.language.split('-')[0]
}

export default ({ app, store }) => {
  app.i18n = new VueI18n({
    locale: storage.get("Accept-Language") || detectBrowserLanguage() || "en",
    messages: {
      en,
      zh,
      ko,
    },
    fallbackLocale: 'en',
  })
}
