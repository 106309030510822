import { EOSN_LOGIN_ACCOUNT } from "~/common/constants";


const CACHE_MINUTES = 5

interface AccountRecord {
  account: string;
  user_id: string;
}

interface State {
  accounts: any;
  updated: Date;
}

export const state = (): State => ({
  accounts: {},
  updated: new Date(0),
})

export const getters = {
  accounts: (state: State) => (eosn_id: string) => {
    return state.accounts[eosn_id];
  },
}

export const mutations = {
  set_accounts(state: State, payload: AccountRecord[]) {
    if (!payload.length) return; // skip if empty
    state.accounts = payload.reduce((acc, cur) => {
      acc[cur.user_id] = cur.account;
      return acc;
    }, {});
    console.log('store/accounts::set_accounts', state.accounts);
    state.updated = new Date();
  },
}

export const actions = {
  async init(context: any) {
    console.log('store/accounts::init', { context });
    if (Object.keys(context.state.accounts ?? {}).length && new Date().getTime() - new Date(context.state.updated).getTime() < CACHE_MINUTES*60*1000) return;
    const resp = await fetch(`https://edge.pomelo.io/api/login.eosn/accounts?contract=${EOSN_LOGIN_ACCOUNT}`)
    const accounts = await resp.json();
    console.log('store/accounts::init', { context });
    context.commit("set_accounts", accounts?.rows ?? []);
  },
}
