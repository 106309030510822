import { GetTableByScopeResult, GetTableRowsResult } from 'eosjs/dist/eosjs-rpc-interfaces';
import { rpc } from "./config";
import { EOSN_LOGIN_ACCOUNT, POMELO_FUNDING_ACCOUNT } from "./constants";

interface GetTableRowsOptions {
  lower_bound?: any;
  upper_bound?: any;
  limit?: number;
}

// export const NULL_BALANCE = `0.0000 ${POMELO_TOKEN_SYMCODE}`;

export async function get_currency_balance( account: string, contract: string, symcode: string ) {
  const null_balance = `0.0000 ${symcode}`;
  if ( !account || account == null ) return null_balance;
  console.log("common::getters:get_currency_balance", {account, contract, symcode })

  try {
    const result = await rpc.get_currency_balance( contract, account, symcode );
    console.log("common::getters:get_currency_balance", { account, result })
    if ( result.length ) return result[0];
  } catch (err) {
    console.error("common::getters:get_currency_balance", { err })
  }
  return null_balance;
}

export async function get_table_rows(code: string, scope: string, table: string, options: GetTableRowsOptions = {}): Promise<GetTableRowsResult> {
  const lower_bound = options.lower_bound;
  const upper_bound = options.upper_bound;

  console.log("common::getters:get_table_rows", {code, scope, table, options })
  try {
    const result = await rpc.get_table_rows( { json: true, code, scope, table, lower_bound, upper_bound } );
    return result;
  } catch (e: any) {
    console.error("common::getters:get_table_rows", {code, scope, table, error: e.json?.error?.details ?? 'get_table_rows error'});
  }
  return ({more: false, rows: []} as any)
}

export async function get_table_by_scope(code: string, table: string, options: GetTableRowsOptions = {}): Promise<GetTableByScopeResult> {
  const lower_bound = options.lower_bound;
  const upper_bound = options.upper_bound;
  const limit = options.limit;

  console.log("common::getters:get_table_by_scope", {code, table, options })
  try {
    const result = await rpc.get_table_by_scope( { json: true, code, table, lower_bound, upper_bound, limit } );
    return result;
  } catch (e: any) {
    console.error("common::getters:get_table_by_scope", {code, table, error: e.json?.error?.details ?? 'get_table_by_scope error'});
  }
  return ({more: false, rows: []} as any)
}

export async function get_pomelo_globals() {
  const data = await fetch(`https://edge.pomelo.io/api/pomelo/globals?contract=${POMELO_FUNDING_ACCOUNT}`);
  const globals = await data.json();
  console.log("common::getters:get_pomelo_globals", { globals });
  return globals;
}

export async function get_eosn_login_user( user_id: string ) {
  console.log("common::getters:get_eosn_login_user", { user_id })
  const result = await get_table_rows( EOSN_LOGIN_ACCOUNT, EOSN_LOGIN_ACCOUNT, "users", {lower_bound: user_id, upper_bound: user_id});
  return result.rows[0] || null;
}

export async function get_eosn_login_acount( user_id: string ) {
  const user = await get_eosn_login_user( user_id );
  console.log("common::getters:get_eosn_login_acount", { user, user_id })
  if ( !user ) return null;
  return user.accounts[0] || null;
}

export async function get_pomelo_active_round() {
  const { round_id } = await get_pomelo_globals();
  console.log("common::getters:get_pomelo_active_round", { round_id })
  const result = await get_table_rows( POMELO_FUNDING_ACCOUNT, POMELO_FUNDING_ACCOUNT, "rounds", {lower_bound: round_id, upper_bound: round_id});
  return result.rows[0];
}

export async function get_account( account: string ) {
  try {
    const result = await rpc.get_account( account );
    console.log("common::getters:get_account", {account, result })
    return result;
  } catch (e) {
    console.error("common::getters:get_account", {account, e })
    return null;
  }
}

export async function is_account_exists( account: string ) {
  try {
    await rpc.get_account( account );
    console.log("common::getters:is_account_exists", {account, exists: true })
    return true;
  } catch (e) {
    console.log("common::getters:is_account_exists", {account, exists: false })
    return false;
  }
}
