import * as storage from "../common/storage"

interface Document {
    id: "privacy" | "terms",
    name: String,
    titles: Array<{
        key: String
    }>
}

interface State {
  documents: Array<Document>;
  contact: Object;
}

export const state = (): State => ({
    documents: [
        {
            id: "privacy",
            name: "legal.documents.privacy.title",
            titles: [
                { key: "legal.documents.privacy.content.section_1.title" },
                { key: "legal.documents.privacy.content.section_2.title" },
                { key: "legal.documents.privacy.content.section_3.title" },
                { key: "legal.documents.privacy.content.section_4.title" },
                { key: "legal.documents.privacy.content.section_5.title" },
                { key: "legal.documents.privacy.content.section_6.title" },
                { key: "legal.documents.privacy.content.section_7.title" },
                { key: "legal.documents.privacy.content.section_8.title" },
                { key: "legal.documents.privacy.content.section_9.title" },
                { key: "legal.documents.privacy.content.section_10.title" },
                { key: "legal.documents.privacy.content.section_11.title" },
                { key: "legal.documents.privacy.content.section_12.title" },
                { key: "legal.documents.privacy.content.section_13.title" },
            ]
        },
        {
            id: "terms",
            name: "legal.documents.terms.title",
            titles: [
                { key: "legal.documents.terms.content.section_1.title" },
                { key: "legal.documents.terms.content.section_2.title" },
                { key: "legal.documents.terms.content.section_3.title" },
                { key: "legal.documents.terms.content.section_4.title" },
                { key: "legal.documents.terms.content.section_5.title" },
                { key: "legal.documents.terms.content.section_6.title" },
                { key: "legal.documents.terms.content.section_7.title" },
                { key: "legal.documents.terms.content.section_8.title" },
                { key: "legal.documents.terms.content.section_9.title" },
                { key: "legal.documents.terms.content.section_10.title" },
                { key: "legal.documents.terms.content.section_11.title" },
                { key: "legal.documents.terms.content.section_12.title" },
                { key: "legal.documents.terms.content.section_13.title" },
                { key: "legal.documents.terms.content.section_14.title" },
                { key: "legal.documents.terms.content.section_15.title" },
                { key: "legal.documents.terms.content.section_16.title" },
                { key: "legal.documents.terms.content.section_17.title" },
                { key: "legal.documents.terms.content.section_18.title" },
                { key: "legal.documents.terms.content.section_19.title" },
                { key: "legal.documents.terms.content.section_20.title" },
                { key: "legal.documents.terms.content.section_21.title" },
                { key: "legal.documents.terms.content.section_22.title" },
                { key: "legal.documents.terms.content.section_23.title" },
                { key: "legal.documents.terms.content.section_24.title" },
                { key: "legal.documents.terms.content.section_25.title" },
                { key: "legal.documents.terms.content.section_26.title" },
                { key: "legal.documents.terms.content.section_27.title" },
            ]
        },
    ],
    contact: {
        email_eos_nation_support: "support@pomelo.io"
    }
})

export const getters = {}

export const mutations = {}

export const actions = {
    init() {
        console.log('legalStore/init');
    }
}