import util from "util";
import JSONBig from 'json-bigint'
import * as storage from "./storage"
import { sanitizeGrant, deSanitizeGrant } from "@/common/sanitize"
import cloneDeep from 'lodash/cloneDeep'

interface APIResponse {
  data: any;
  status: number;
}

export class ApiError extends Error {
  detail: string;
  constructor(code: string, detail: string = "") {
    super(code);
    this.detail = detail;
  }
}


let context: any = null;

function preProcessData(url: string, method: string, data: any) {
  if(!data || data instanceof FormData) return data;
  let res = cloneDeep(data);
  if ("grant_translations" in data) {
    res = sanitizeGrant(res);
  }
  return res;
}

function preProcessParams(params: any) {

  if (!params) return params;
  const res = Object.assign({}, params);

  return res;
}

function postProcessResults(url: string, method: string, params: any, results: APIResponse) {
  if (!results?.data) return results
  if ("grant_translations" in results?.data) {
    results.data = deSanitizeGrant(results.data);
  }

  return results;
}

function apiFactory(url: string, method: string, contentType='application/json; charset=utf-8', needLanguageHeader = true) {
  return async function (data: any, params: any, ...url_params) {
    let requestUrl = url;
    if (url_params.length > 0) {
      requestUrl = util.format(url, ...url_params);
      console.log(requestUrl);
    }

    // default lang:
    let acceptLanguage = storage.get("Accept-Language") || "en";

    // TEMP - overide for backend language
    switch (acceptLanguage) {
      case "fr":
      case "es":
        acceptLanguage = "en";
      case "cn":
        acceptLanguage = "zh";
    }

    // update accessToken
    if(context.$auth0.isAuthenticated){
      try{
        const accessToken = await context.$auth0.getTokenSilently();
        context.$axios.setToken(accessToken, 'Bearer');
      }
      catch(e){
        console.log('API: getTokenSilently failed, logout')
        context.$auth0.logout({returnTo: window.location.origin});
      }
    }

    let defaultsHeaders = context.$axios.defaults.headers.common;

    let headers = {...defaultsHeaders};
    headers['Content-Type'] = contentType;
    if (needLanguageHeader) {
      headers['X-Accept-Language'] = acceptLanguage;
    }

    return context.$axios({
      method: method,
      url: requestUrl,
      data: preProcessData(url, method, data),
      params: preProcessParams(params),
      headers: headers
    }).then((res: APIResponse) => {
      if (res.status !== 200 && res.status !== 201) throw res;
      return postProcessResults(url, method, params, res.data);
    })
    .catch((err: any) => {
      if (err instanceof SyntaxError) return; // workaround for axios synthax error bug(?)
      if (err.response?.status === 413) throw new ApiError("entity_too_large")
      throw new ApiError(err.response?.data?.errors?.[0]?.code ?? "something_went_wrong", err.response?.data?.errors?.[0].detail ?? "");
    });
  }
}

export const API = {
  // grants
  listGrants: apiFactory('/grants', "GET"),
  createGrants: apiFactory('/grants', "POST"),
  getGrants: apiFactory('/grants/by_name/%s', "GET"),
  updateGrants: apiFactory('/grants/by_name/%s', "POST"),
  deleteGrants: apiFactory('/grants/by_name/%s', "DELETE"),
  getGrantsContributions: apiFactory('/grants/by_name/%s/contributions', "GET"),
  getGrantsContributors: apiFactory('/grants/by_name/%s/contributors', "GET"),
  postGrantsFollows: apiFactory('/grants/by_name/%s/follows', "POST"),
  deleteGrantsFollows: apiFactory('/grants/by_name/%s/follows', "DELETE"),
  getGrantsMatchingEstimate: apiFactory('/grants/by_name/%s/matching_estimate', "GET"),
  reportGrants: apiFactory('/grants/by_name/%s/report', "POST"),
  retireGrants: apiFactory('/grants/by_name/%s/retire', "POST"),
  updateGrantsTranslations: apiFactory('/grants/by_name/%s/translations/%s', "POST"),
  getGrantsCategories: apiFactory('/grants/categories', "GET"),
  lookupGrantName: apiFactory('/grants/lookup/name', "GET"),

  // languages
  listLanguages: apiFactory('/languages', "GET"),

  // regions
  listRegions: apiFactory('/regions', "GET"),

  // matching rounds
  listRounds: apiFactory('/rounds', "GET"),
  listSeasons: apiFactory('/seasons', "GET"),
  listActiveRounds: apiFactory('/rounds/overview', "GET"),
  applyRound: apiFactory('/rounds/by_id/%s/apply', "POST"),

  // tokens
  listTokens: apiFactory('/tokens', "GET"),

  // upload
  uploadProjectLogo: apiFactory('/upload/project_logo', "POST", "multipart/form-data"),
  uploadProjectImage: apiFactory('/upload/project_image', "POST", "multipart/form-data"),
  uploadUserAvatar: apiFactory('/upload/user_avatar', "POST", "multipart/form-data"),

  // user
  userGrants: apiFactory('/user/grants', "GET"),
  userCollections: apiFactory('/user/collections', "GET"),
  userProfile: apiFactory('/user/profile', "GET"),
  modifyUserProfile: apiFactory('/user/profile', "POST"),

  // users
  getUser: apiFactory('/users/by_username/%s', "GET"),
  getUserContributions: apiFactory('/users/by_username/%s/contributions', "GET"),
  repostUser: apiFactory('/users/by_username/%s/report', "POST"),
  userRegister: apiFactory('/users/register', "POST"),
  listUsernames: apiFactory('/users/usernames', "GET"),

  // proxied to account api
  postUserLinkedAccount: apiFactory(`/user/linked_accounts`, "POST", "application/json", false),
  deleteUserLinkedAccount: apiFactory(`/user/linked_accounts/by_name/%s`, "DELETE", "application/json", false),
  verifySocialsPort: apiFactory('/user/socials/port/verify', "POST", "application/json", false),
  disconnectSocialsPort: apiFactory('/user/socials/port', "DELETE", "application/json", false),
  disconnectSocial: apiFactory('/user/socials/oauth2/%s', "DELETE", "application/json", false),
  getUserSocials: apiFactory('/user/socials', "GET", "application/json", false),
  getUserSocialsUrl: apiFactory('/user/socials/oauth2/verification_url', "GET", "application/json", false),
  verifySocialsSmsRequest: apiFactory('/user/socials/sms/request', "POST", "application/json", false),
  verifySocialsSmsVerify: apiFactory('/user/socials/sms/verify', "POST", "application/json", false),
  disconnectSocialsSms: apiFactory('/user/socials/sms', "DELETE", "application/json", false),

  // collections
  listCollections: apiFactory('/collections', "GET"),
  getCollection: apiFactory('/collections/by_name/%s', "GET"),
  createCollection: apiFactory('/collections', "POST"),
  deleteCollection: apiFactory('/collections/by_name/%s', "DELETE"),
  editCollection: apiFactory('/collections/by_name/%s', "POST"),
  followCollection: apiFactory('/collections/by_name/%s/follows', "POST"),
  unfollowCollection: apiFactory('/collections/by_name/%s/follows', "DELETE"),
  addGrantToCollection: apiFactory('/collections/by_name/%s/%s', "POST"),
  removeGrantFromCollection: apiFactory('/collections/by_name/%s/%s', "DELETE"),

  //  kyc
  getKYCStatus: apiFactory('/user/kyc', "GET"),
  getKYCUrl: apiFactory('/user/kyc/verification_url?redirect_url=%s', "GET"),
}

export default ({ app }, inject) => {
  context = app;
  inject("api", API);

  // context.$axios.defaults.transformResponse = [
  //   function (data) {
  //     return JSONBig.parse(data);
  //   }
  // ]
}
