const localStorage = global.localStorage;

export const POMELO_PFP = "pomelo_pfp";
export const POMELO_CART = "pomelo_cart";
export const POMELO_WALLET = "pomelo_wallet";
export const POMELO_DEVICE = "pomelo_device";
export const POMELO_LANGUAGE = "Accept-Language";
export const POMELO_GRANTS = "pomelo_grants"

export function set(key: string, value: any) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        localStorage.setItem(key, value);
    }
}

export function add(key: string, value: any) {
    try {
        set(key, Object.assign(get(key) || {}, value));
    } catch (err) {
        console.error("common/storage::add", {err, key, value});
    }
}

export function get(key: string) {
    const value = localStorage.getItem(key);
    if ( !value ) return value;
    try {
        return JSON.parse(value);
    } catch (e) {
        return value;
    }
}

export function remove(key: string) {
    localStorage.removeItem(key);
}
