export const state = () => ({
    kyc_verification_url:'',
    kyc_status:''
})

export const mutations = {
    setKYCVerificationUrl(state: any, val: any) {
            state.kyc_verification_url = val;
    },
    setKYCStatus(state: any, val: any) {
        state.kyc_status = val;
    }
}