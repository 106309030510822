

const CACHE_MINUTES = 5

interface NFT {
  owner: string;
  contract: string;
  asset_id: string;
  collection: any;
  schema: any;
  template: any;
  name: string;
  is_burnable: boolean;
  is_transferable: boolean;
  // ... more
}
type NFTs = { [key: string]: NFT };

interface State {
  achievements: any;
  badges: any;
  updated: Date;
}

export const state = (): State => ({
  achievements: {},
  badges: {},
  updated: new Date(0),
})

export const getters = {
  achievements: (state: State) => (account: string) => {
    return state.achievements[account] ?? {};
  },
  badges: (state: State) => (account: string) => {
    return state.badges[account] ?? {};
  },
}

export const mutations = {
  set_achievements(state: State, payload: NFT[]) {
    console.log('store/nft::set_achievements NFTs ');
    if (!payload.length) return; // skip if empty
    state.achievements = payload.reduce((acc, cur) => {
      (acc[cur.owner] ??= []).push(cur);
      return acc;
    }, {});
    state.updated = new Date();
  },
  set_badges(state: State, payload: NFT[]) {
    console.log('store/nft::set_badges NFTs ');
    if (!payload.length) return; // skip if empty
    state.badges = payload.reduce((acc, cur) => {
      (acc[cur.owner] ??= []).push(cur);
      return acc;
    }, {});;
    state.updated = new Date();
  },
}

export const actions = {
  async init(context: any) {
    //temporarily disable https://github.com/pomelo-io/pomelo-stories/issues/596
    return;
    console.log('store/nft::init', { context });
    const badges_templates = [4741, 1797, 2657, 5035]
    // refresh no often than CACHE_MINUTES minutes
    if (Object.keys(context.state.achievements ?? {}).length && Object.keys(context.state.badges ?? {}).length && new Date().getTime() - new Date(context.state.updated).getTime() < CACHE_MINUTES*60*1000) return;
    const [achievements, badges] = await Promise.all([
      fetch(`https://edge.pomelo.io/api/atomicassets/assets_ids?collection_name=pomelo&schema_name=achievements&page=1&limit=1000&order=asc&sort=template_id&burned=false`),
      fetch(`https://edge.pomelo.io/api/atomicassets/assets_ids?&template_whitelist=${badges_templates.join(',')}&page=1&limit=1000&order=asc&sort=template_id&burned=false`)
    ]);

    context.commit("set_achievements", (await achievements.json()).data ?? []);
    context.commit("set_badges", (await badges.json()).data ?? []);
  },
}
