// Store for pomelo globals: categories, regions, tokens, etc

import { API } from "@/common/API"


interface Category {
    id: number;
    name: string;
}

interface Region {
    id: number;
    name: string;
}

interface Regions {
    data: Region[];
    updated: Date;
}

interface Categories {
    data: Category[];
    updated: Date;
}

interface State {
    regions: Regions;
    categories: Categories;
}

export const state = (): State => ({
    regions: {data: [], updated: null},
    categories: {data: [], updated: null},
})


export const getters = {
}

export const mutations = {
    setRegions(state: any, val: any) {
        console.log("🛒 store/pomeloStore::mutations:setRegions", val);
        state.regions.data = val.sort((a, b) => a.id - b.id);
        state.regions.updated = new Date();
    },
    setCategories(state: any, val: any) {
        console.log("🛒 store/pomeloStore::mutations:setCategories", val);
        state.categories.data = val.sort((a, b) => a.id - b.id);
        state.categories.updated = new Date();
    },
}

export const actions = {
    async getRegions(context: any, force: boolean = false) {
        // infinite cache
        if (!force && context.state.regions.updated) return context.state.regions.data;
        const res = await API.listRegions({}, {})
        context.commit("setRegions", res?.data ?? []);
        return context.state.regions.data;
    },
    async getCategories(context: any, force: boolean = false) {
        // infinite cache
        if (!force && context.state.categories.updated) return context.state.categories.data;
        const res = await API.getGrantsCategories({}, {})
        context.commit("setCategories", res?.data ?? []);
        return context.state.categories.data;
    }
}