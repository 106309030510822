// export separate icons here

export {
    // message icons
    CloseCircleFill,
    CloseCircleOutline,
    InfoCircleOutline,
    CheckCircleFill,
    CheckCircleOutline,
    ExclamationCircleFill,
    ExclamationCircleOutline,
    LoadingOutline,
    InfoCircleFill,
    // spinner
    Loading3QuartersOutline,
    // collapse header
    UpOutline,
    DownOutline,
    // pagination
    RightOutline,
    LeftOutline,
    //
    CloseOutline,
    CloseSquareOutline,
    CheckOutline,
    LinkOutline,
    ClockCircleFill,
    CopyOutline,
    QuestionCircleOutline,
    EditOutline,
    HeartOutline,
    HeartFill,
    PlusOutline,
    MinusOutline,
    LockOutline,
    SearchOutline,
} from '@ant-design/icons';
