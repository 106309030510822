import cloneDeep from 'lodash/cloneDeep'
import * as storage from "~/common/storage"
import { getTokens, Token } from '~/common/tokens';

interface CartEntry {
    name: string;
    tokenAmount: number;
    currentToken: string;
    currentTokenId: number;
    matching_round: any;
    active_round: any;
    referer: string;
}

interface State {
    grantList: CartEntry[];
    tokens: Token[];
    isLoaded: boolean;
}

interface AddedGrant {
    grant: any;
    referer: string;
}

export const state = (): State => ({
    grantList: [],
    tokens: [],
    isLoaded: false,
})

export const getters = {
    isExists: (state: State) => (grantName: string) => {
        let grantList = cloneDeep(state.grantList);
        return !!grantList.find((grant: CartEntry) => grant.name == grantName);
    },
    getTokenListWithAmount: (state: State) => () => {
        const result = new Map();
        for ( const item of state.grantList ) {

            const tokenSymbol = item.currentToken;
            const tokenAmount = Number(item.tokenAmount);
            if (result.has(tokenSymbol)) {
                const totalAmount = (Number(result.get(tokenSymbol)) + tokenAmount).toFixed(4);
                result.set(tokenSymbol,totalAmount)
            } else {
                result.set(tokenSymbol,tokenAmount)
            }
        }
        return result;
    },
    getGrantList: (state: State) => () => {
        return storage.get( storage.POMELO_CART ) ?? []
    }
}

export const mutations = {
    setGrantList(state: State, grantList: CartEntry[]) {
        console.log('setGrantList', grantList)
        for(const grant of grantList){
            const min_amount = state.tokens.find(token => token.symcode === grant.currentToken)?.min_amount ?? 1;
            grant.tokenAmount = Math.max(grant.tokenAmount, min_amount);
        }
        state.grantList = grantList;
        storage.set(storage.POMELO_CART, state.grantList);
    },
    setIsLoaded(state: State, isLoaded: boolean) {
        console.log('setIsLoaded', isLoaded)
        state.isLoaded = isLoaded;
    },
    setTokens(state: State, tokens: Token[]) {
        console.log('setTokens', tokens)
        state.tokens = tokens;
    },
}

export const actions = {
    init(context: any) {
        console.log('cartStore/init')
        const grantList = storage.get( storage.POMELO_CART ) ?? []
        getTokens().then((tokens: any) => {
            context.commit("setTokens", tokens);
        });

        context.commit("setGrantList", grantList);
        context.commit("setIsLoaded", true);
    },
    add(context: any, targetGrant: AddedGrant) {
        const grantList = storage.get( storage.POMELO_CART ) ?? []
        const grantToAdd = targetGrant.grant
        const round = grantToAdd.matching_round ?? grantToAdd.active_round;

        // check
        const isExists = !!grantList.find(grant => grant.name === grantToAdd.name);
        if(isExists) return;
        if(!round) return;
        if(!context.rootGetters['userStore/allowedToDonate'](grantToAdd)) return;

        let allowedTokens = grantToAdd.accepted_tokens
            .filter((token: any) => round.approved_tokens.map((t: any) => t.id).includes(token.id));
        if(allowedTokens.length === 0) return;

        // add: current token, token amount
        grantToAdd.accepted_tokens = allowedTokens;
        grantToAdd.tokenAmount = 1;
        grantToAdd.currentTokenId = allowedTokens[0].id;
        grantToAdd.currentToken = allowedTokens[0].symbol;
        grantToAdd.referer = targetGrant.referer;
        grantList.push(grantToAdd);

        context.commit("setGrantList", grantList);
    },
    remove(context: any, grantNames: string[]){
        let grantList = storage.get( storage.POMELO_CART ) ?? []
        grantList = grantList.filter((grant: CartEntry) => !grantNames.includes(grant.name));

        context.commit("setGrantList", grantList);
    },
    applyAll(context: any, grantName: string){
        const grantList = storage.get( storage.POMELO_CART ) ?? []
        const fromGrant = grantList.find((grant: CartEntry) => grant.name === grantName);
        if (!fromGrant) return;

        grantList.forEach((grant: any) => {
            if(grant.currentTokenId === fromGrant.currentTokenId){
                grant.tokenAmount = fromGrant.tokenAmount;
            }
        })

        context.commit("setGrantList", grantList);
    },
    updateTokenAmount(context: any, params: any) {
        console.log('updateTokenAmount',params)
        const {grantName, tokenAmount} = params;

        const grantList = storage.get( storage.POMELO_CART ) ?? []
        const grant = grantList.find((gr: any) => gr.name === grantName);
        if(!grant) return;
        grant.tokenAmount = tokenAmount;

        context.commit("setGrantList", grantList);
    },
    updateCurrentToken(context: any, params: any){
        const {grantName, symbol} = params;

        let grantList = cloneDeep(context.state.grantList);
        const grant = grantList.find((gr: any) => gr.name === grantName);
        if(!grant) return;
        grant.currentToken = symbol;
        grant.currentTokenId = grant.accepted_tokens.find((token: any) => token.symbol === symbol)?.id;

        context.commit("setGrantList", grantList);
    },
    clear(context: any) {
        context.commit("setGrantList", []);
    },
}
