import { POMELO_FUNDING_ACCOUNT } from "./constants"
import { get_table_rows } from "./getters";

export interface Token {
  symcode: string;
  precision: number;
  contract: string;
  min_amount: number;   // 1 means 1 EOS
}

export async function getTokens(): Promise<Token[]> {
  const { rows } = await get_table_rows( POMELO_FUNDING_ACCOUNT, POMELO_FUNDING_ACCOUNT, "tokens");
  return rows.map( (row: any) => ({
    symcode: row.sym.split(',')[1],
    precision: +row.sym.split(',')[0],
    contract: row.contract,
    min_amount: +row.min_amount / (10 ** +row.sym.split(',')[0])
  }))
}
