import Vue from 'vue'

// Import the Auth0 configuration
// import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin, useAuth0 } from "./auth";

let context;
const domain = process.env.AUTH0_DOMAIN;
const clientId = process.env.AUTH0_CLIENT_ID;
const audience = process.env.AUTH0_AUDIENCE;

const options = {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
      console.log("onRedirectCallback appState：", appState);
      console.log("onRedirectCallback context:",context);
      context.router.push(
          appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
  }
}

// Install the authentication plugin here
// Vue.use(Auth0Plugin, options);
export default ({ app }, inject) => {
  context = app;
  // app.auth0 = useAuth0(options);
  inject("auth0", useAuth0(options, context));
}
