// import { POMELO_TOKEN_SYMCODE } from "@/common/constants"
import { API } from "@/common/API"
import { parseNumber } from "@/common/helpers"

export const state = () => ({
  userProfile: null,  // {}

  // Contribution Stats
  total_contribution_stats: {
    amount: 0,                          // "80.32"
    contributions: 0,                   // 9
    total_contributions_rank: null      // 4
  },

  // EOSN account
  created_at: '',     // "2021-10-18T18:07:47Z"
  email: '',          // "<account>@gmail.com"
  eosn_id: '',        // "43512.d.eosn"
  name: '',           // "Denis 🚀"
  updated_at: '',     // "2021-10-21T00:55:48Z"
  username: '',       // "denis"
  website: '',        // "https://eosnation.io"

  // wallet details
  actor: '',
  permission: '',
  publicKey: '',
  wallet: '',
  protocol: '',
  chain: 'eos',

  // linked account
  linkedAccount: '',
  balance: `0.0000`,

  // donated grants
  donatedGrants: {
    username: null,
    grants: {},
    since: null,
    updated: new Date(0),
    cacheMinutes: 60
  }
})



export const getters = {
  //TODO: define types for state & grant
  allowedToDonate: (state: any) => (grant: any) => {
    return grant
      && grant.funding_address != state.linkedAccount
      && grant.owner
      && grant.owner.username != state.userProfile?.username
      && (!state.userProfile?.username || !grant.members?.find(mem => mem.username === state.userProfile.username))
      && grant.state == 'PUBLISHED'
      && (grant.matching_round?.state == 'APPROVED'                   // from overview
        && new Date() > new Date(grant.matching_round?.start_at)
        && new Date() < new Date(grant.matching_round?.end_at) ||
        grant.active_round?.state == 'APPROVED'                   // from grant details
        && new Date() > new Date(grant.active_round?.start_at)
        && new Date() < new Date(grant.active_round?.end_at)
      )
  }
}

export const mutations = {
  setUserProfile(state: any, val: any) {
    state.userProfile = val;

    // Contribution Stats
    if ( !val?.total_contribution_stats ) return;
    const { amount, contributions, total_contributions_rank } = val.total_contribution_stats;
    state.total_contribution_stats = {
      amount: Number(amount),
      total_contributions_rank: total_contributions_rank,
      contributions: contributions
    }

    // EOSN account
    const { created_at, email, eosn_id, name, updated_at, username, website } = val;
    state.created_at = created_at;
    state.email = email;
    state.eosn_id = eosn_id;
    state.name = name;
    state.updated_at = updated_at;
    state.username = username;
    state.website = website;
  },

  setSaveWallet(state: any, payload: any) {
    const { actor, permission, publicKey, wallet, protocol, chain } = payload;
    console.log("setSaveWallet", payload);
    state.actor = actor;
    state.permission = permission;
    state.publicKey = publicKey;
    state.wallet = wallet;
    state.protocol = protocol;
    state.chain = chain;
  },
  setLinkedAccount(state: any, val: any) {
    state.linkedAccount = val;
  },
  setBalance(state: any, val: any) {
    state.balance = val;
  },
  setDonatedGrants(state: any, val: any) {
    state.donatedGrants = { ...state.donatedGrants, ...val };
    state.donatedGrants.updated = new Date();
  },
}

interface Donations {
  donations: number;
  value: number;
}

export const actions = {
  async getDonatedGrants(context: any, force: boolean = false): Promise<{[key: string]: Donations}> {
    const roundStart = context.rootGetters['roundStore/getActiveRounds']()?.at(-1)?.start_at;
    const username = context.state.userProfile?.username;
    console.log('getDonatedGrants', {roundStart}, {username}, {force})
    if (!roundStart || !username) return {};
    // 60 minutes cache
    if (!force
      && context.state.donatedGrants?.username === username
      && context.state.donatedGrants?.since === roundStart
      && new Date().getTime() - context.state.donatedGrants.updated.getTime() < context.state.donatedGrants.cacheMinutes * 60 * 1000) {
        return context.state.donatedGrants.grants;
    }
    const since = new Date(roundStart ?? 0).getTime();
    let donated = {};
    let page = 1;
    let done = false;
    while (!done){
      const res = await API.getUserContributions({}, { page, limit: 50 }, username);
      donated = res?.data?.reduce((acc: any, cur: any) => {
        done = since > new Date(cur.created_at).getTime();
        if (done) return acc;
        (acc[cur.grant.name] ??= {donations: 0, value: 0}).donations ++;
        acc[cur.grant.name].value += parseNumber(cur.contribution_equivalent.amount)
        return acc;
      }, donated);
      if (!res?.meta?.next_page) break;
      page++;
    }
    context.commit("setDonatedGrants", { grants: donated, username: username, since: roundStart });
    return context.state.donatedGrants.grants;
}
}
