
export default {
  props: {
    isActive: true,
    onClick: {
      type: Function,
      required: true
    }
  }
}
